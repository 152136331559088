.spinner {
  height: 1.25rem !important;
  width: 1.25rem !important;
  margin-right: 0.5rem;
  font-size: 0.75rem;
}

.spinner-extra-styles {
  opacity: 0.5 !important;
  cursor: 'default' !important;
}

.cursor-pointer {
  cursor: pointer;
}

.empty-groups {
  cursor: default;
  opacity: 0.5;
}
