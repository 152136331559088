.opacity-75 {
  opacity: 0.75;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.region {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty-demographics {
  text-align: center;
  margin-top: 1rem;
}

.demographics-title {
  height: 5rem;
}

.download-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
}
