.cursor-pointer {
  cursor: pointer;
}

.info-active {
  color: #09558f;
}

.info-inactive {
  color: #79b2db;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.higher-awareness {
  background-color: #23558b;
  color: white;
  font-size: 0.875rem;

  @media only screen and (max-width: 539px) {
    font-size: 0.625rem;
  }
}

.lower-awareness {
  background-color: #d0e1f1;
  color: #475058;
  font-size: 0.875rem;

  @media only screen and (max-width: 539px) {
    font-size: 0.625rem;
  }
}

.awareness-container {
  margin-left: 12rem;

  @media only screen and (max-width: 767px) {
    margin-left: 0;
  }

  @media only screen and (max-width: 539px) {
    margin-left: 0;
  }
}

.heatmap-information {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  align-items: center;

  @media only screen and (max-width: 767px) {
    justify-content: space-between;
  }

  @media only screen and (max-width: 539px) {
    justify-content: space-between;
  }
}

.show-details {
  font-size: 0.75rem;
  user-select: none;

  @media only screen and (max-width: 539px) {
    font-size: 0.625rem;
  }
}

.details-icon {
  margin-right: 0.25rem;
  font-size: 1.25rem;

  @media only screen and (max-width: 539px) {
    font-size: 1rem;
  }
}

.chart-wrapper {
  height: 70vh;
}
