.card-height {
  height: 490px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.response-rate-pie {
  margin-bottom: 1rem;
}

@media (min-width: 1400px) {
  .respose-rate-height {
    height: 480px;
  }
}

@media (max-width: 1400px) {
  .respose-rate-height {
    height: 405px;
  }
}

@media (max-width: 320px) {
  .respose-rate-height {
    height: 350px;
  }
}

.respose-rate-header {
  display: flex;
  justify-content: space-between;

  @media (max-width: 360px) {
    flex-direction: column;
  }
}

.opacity-75 {
  opacity: 0.75;
}

.download-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: -1.25rem;
  right: -1.25rem;
}
