.body-styles {
  background-color: #f4f4f4 !important;
}

.header-styles > .popover-header {
  background-color: #dfe3fa !important;
}

.header-styles > .popover-header::before {
  border-bottom: #dfe3fa !important;
}

.body-styles > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  border-bottom-color: #dfe3fa !important;
}

.body-styles > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
  border-top-color: #f4f4f4 !important;
}
