.title {
  padding: 1rem 0 3.5rem;
  align-self: flex-start;
  font-size: 1.25rem;
}

.slider-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;
}
