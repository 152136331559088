.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.opacity-75 {
  opacity: 0.75;
}

.card-height {
  @media only screen and (min-width: 1200px) {
    height: 490px;
  }
}

.title {
  font-size: 18px;

  @media only screen and (max-width: 1300px) and (min-width: 1200px) {
    font-size: 16px;
  }
}

.download-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
}
