.cursor-pointer {
  cursor: pointer;
}

.opacity-75 {
  opacity: 0.75;
}

.card-height {
  @media screen and (min-width: 1200px) {
    height: 490px;
  }
}

.column-chart-height {
  height: 100%;
  @media screen and (max-width: 767px) {
    height: 490px;
  }
}

.download-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
}
