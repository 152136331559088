.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.opacity-75 {
  opacity: 0.75;
}

.areas-of-risks-height {
  height: 600px;

  @media screen and (min-width: 540px) and (max-width: 768px) {
    height: 500px;
  }
}

.pb-6 {
  padding-bottom: 5rem;
}

.chart-mobile {
  height: 100%;

  @media screen and (max-width: 360px) {
    padding-bottom: 2rem;
  }
}

.categories-mobile {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 10px;
  padding: 0 1rem;
  justify-content: center;

  @media screen and (max-width: 414px) {
    flex-direction: column;
  }

  @media screen and (min-width: 541px) {
    display: none;
  }
}

.radar-chart-mobile {
  @media screen and (max-width: 541px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.category {
  margin: 0 5px;
  color: #73788d;
}

.category-number {
  font-weight: bold;
}

.download-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
}
